<template>
  <div class="variantSelect">
    <el-dropdown trigger="click">
      <span class="el-dropdown-link">
        <img :src="variants[selected].imgSmall" />
        <label>{{ variants[selected].label }}</label>
        <i class="material-icons">arrow_drop_down</i>
      </span>
      <el-dropdown-menu slot="dropdown" class="dropdown">
        <el-dropdown-item v-for="item in variants" :key="item.value">
          <img :src="item.img" :class="{ selected: selected == item.value }" @click="changeVariant(item.value)" />
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
import { Dropdown, DropdownItem, DropdownMenu } from 'element-ui';
export default {
  components: {
    elDropdown: Dropdown,
    elDropdownItem: DropdownItem,
    elDropdownMenu: DropdownMenu
  },
  props: {
    variants: Array,
    selected: Number
  },
  methods: {
    changeVariant(value) {
      this.$emit('change', value);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@shared/styles/_colors.scss';

.variantSelect {
  width: 100%;
  border: 1px solid silver;
  border-radius: 4px;
  label {
    margin: 0 15px;
    font-weight: bolder;
    outline: none;
    font-size: var(--text-size-small);
  }
  img {
    height: 45px;
  }
  .el-dropdown-link {
    img {
      margin: 5px;
    }
  }
  i.material-icons {
    vertical-align: middle;
    font-size: 2rem;
    color: $color-grey;
  }
}
.selected {
  border: 3px solid #d9dde0;
}
.dropdown {
  li {
    padding: 0;
  }
  padding: 0;
}

.el-dropdown-link {
  display: block;
  width: 273px;

  label {
    pointer-events: none;
  }
}

.el-dropdown-menu.el-popper.dropdown {
  padding: 8px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.el-dropdown-menu__item:focus,
.el-dropdown-menu__item:not(.is-disabled):hover {
  background-color: white;
}
.el-popper[x-placement^='bottom'] {
  margin-top: 1px;
}

.el-dropdown-menu__item {
  img {
    width: 100%;
  }

  img:hover {
    opacity: 0.7;
  }
}
</style>

<style>
.el-popper[x-placement^='bottom'] .popper__arrow {
  display: none;
}
</style>
