<template>
  <div class="row">
    <div class="alignment-control col-12">
      <label>
        <input @change="changeAlignment" v-model="alignment" value="text-left" :name="name" type="radio" />
        <div>
          <i class="material-icons">format_align_left</i>
        </div>
      </label>
      <label>
        <input @change="changeAlignment" v-model="alignment" value="text-center" :name="name" type="radio" />
        <div>
          <i class="material-icons">format_align_center</i>
        </div>
      </label>
      <label>
        <input @change="changeAlignment" v-model="alignment" value="text-right" :name="name" type="radio" />
        <div>
          <i class="material-icons">format_align_right</i>
        </div>
      </label>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TextAlignment',
  props: {
    name: {
      type: String
    },
    initialValue: {
      type: String,
      required: true,
      default: 'text-left'
    }
  },
  data() {
    return {
      alignment: this.initialValue
    };
  },
  watch: {
    initialValue() {
      this.alignment = this.initialValue;
    }
  },
  methods: {
    changeAlignment() {
      this.$emit('alignmentChanged', this.alignment);
    }
  }
};
</script>

<style scoped lang="scss">
@import '@shared/styles/_colors.scss';
@import '@shared/styles/_helpers.scss';

i.material-icons {
  font-size: var(--subtitle-size);
  vertical-align: middle;
  color: $color-secondary;
}

.alignment-control {
  label {
    > input {
      visibility: hidden;
      position: absolute;
    }

    > input + div {
      padding: 5px;
      cursor: pointer;
    }

    > input:checked + div i {
      color: $color-primary;
    }

    > input:hover + div i {
      color: $color-primary;
    }
  }

  label:last-child > input + div {
    border-top-left-radius: $general-border-radius;
    border-bottom-left-radius: $general-border-radius;
  }

  label:first-child > input + div {
    border-top-right-radius: $general-border-radius;
    border-bottom-right-radius: $general-border-radius;
  }
}
</style>
