<template>
  <div class="add-content-block-marker" v-if="!inTranslateMode">
    <div class="inner-border"></div>
    <i class="material-icons" @click="$emit('markerClicked')">add</i>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  computed: {
    ...mapGetters('editor', ['inTranslateMode'])
  }
};
</script>
<style scoped lang="scss">
@import '@shared/styles/_helpers.scss';
@import '@shared/styles/_colors.scss';

div.add-content-block-marker {
  height: 12px;
  position: relative;
  text-align: center;
  &:first-of-type {
    margin-top: -15px;
  }
  &:not(:first-of-type) {
    margin-top: 24px;
  }
  .inner-border {
    margin-top: 6px;
    border-top: none;
    border-top: dashed 1px #b1b1b1;
  }

  i {
    display: none;
    color: #b1b1b1;
    cursor: pointer;
    font-size: var(--title-size);
    left: calc(50% - 22px);
    position: absolute;
    top: -10px;
    padding: 0px 10px;
    background-color: white;
    border: dashed 1px #b1b1b1;
    border-radius: $general-border-radius;
  }

  i {
    display: block;
  }

  .inner-border {
  }
}

div.add-content-block-marker:hover {
}
</style>
